<template>
  <div class="map-control" :style="{ top, bottom, left, right }">
    <DragResize
      :active="isActive"
      :parent-id="mapId"
      :to="`${mapId}-draggable-elements`"
      :w="260"
      :h="270"
      :x="10"
      :y="-10"
      :z="2"
      dragHandle=".drag-legend"
      preventActiveBehavior
    >
      <div
        class="drag-legend"
        style="position: absolute; top: 0; height: 40px; width: calc(100% - 40px); z-index: 3; cursor: move"
      ></div>
      <v-card width="100%" height="100%">
        <v-card-title
          flat
          tile
          style="height: 40px; border-bottom: 1px solid lightgray; cursor: move; font-size: 16px"
          class="py-0 d-flex"
        >
          <v-icon size="18">{{ icons.mdiMapLegend }}</v-icon>
          <span class="ml-2">Legend</span>
          <v-icon title="Close" size="18" style="margin-left: auto" @click="close">{{ icons.mdiClose }}</v-icon>
        </v-card-title>
        <v-card-text class="pt-2 pb-0" style="height: calc(100% - 40px); overflow-y: auto">
          <div v-if="showLayerPosition" class="pb-b" style="color: red">
            <p class="mb-0">Left: CDS1</p>
            <p class="mb-0">Right: CDS1</p>
            <p class="mb-0">Both: CDS1</p>
          </div>
          <div v-for="item in labels" :key="item.name">
            <div class="d-flex align-center">
              <div class="rounded-sm" style="height: 10px; width: 30px" :style="{ backgroundColor: item.color }" />
              <span class="ml-5" style="font-size: 13px">{{ item.name }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </DragResize>
    <div v-if="!isActive" class="ol-control" style="position: relative">
      <button title="Legend" icon @click="isActive = true">
        <v-icon color="black" size="20">{{ icons.mdiMapLegend }}</v-icon>
      </button>
    </div>
  </div>
</template>
<script>
import { mdiMapLegend, mdiClose } from '@mdi/js'
import DragResize from '@/components/DragResize.vue'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      icons: { mdiMapLegend, mdiClose },
      isActive: false,
      showLayerPosition: false,
      leftLayers: [],
      rightLayers: [],
    }
  },
  inject: ['map', 'mapId'],
  components: { DragResize },
  computed: {
    ...mapState('map', ['labels']),
  },

  methods: {
    close() {
      this.isActive = false
    },
  },
  watch: {},
  props: {
    top: {
      type: String,
      default: 'unset',
    },
    bottom: {
      type: String,
      default: 'unset',
    },
    left: {
      type: String,
      default: 'unset',
    },
    right: {
      type: String,
      default: 'unset',
    },
  },
}
</script>
