<template>
  <div
    ref="mapMousePosition"
    class="map-control d-flex align-center justify-center map-mouse-position"
    :style="{ top, bottom, left, right }"
  >
    <v-icon color="rgba(94, 86, 105, 0.68)" size="14">{{ icons.mdiCrosshairsGps }}</v-icon>
  </div>
</template>
<script>
import { mdiCrosshairsGps } from '@mdi/js'
import { MousePosition } from 'ol/control.js'
import { transform } from 'ol/proj'
export default {
  inject: ['map'],
  props: {
    top: {
      type: String,
      default: 'unset',
    },
    bottom: {
      type: String,
      default: 'unset',
    },
    left: {
      type: String,
      default: 'unset',
    },
    right: {
      type: String,
      default: 'unset',
    },
    fixed: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      icons: { mdiCrosshairsGps },
    }
  },
  mounted() {
    const el = this.$refs.mapMousePosition
    this.map.addControl(
      new MousePosition({
        target: el,
        coordinateFormat: coordinates => {
          return transform(coordinates, 'EPSG:3857', 'EPSG:4326')
            .map(coordinate => coordinate.toFixed(5))
            .reverse()
            .join(', ')
        },
        className: 'mouse-position',
        placeholder: 'Lat, Lon',
      }),
    )
  },
}
</script>

<style lang="scss">
.map-mouse-position {
  padding: 0 10px;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.1666666667em;
  line-height: 2rem;
  background: white;
  border-radius: 5px;
  color: rgba(94, 86, 105, 0.68);

  .mouse-position {
    margin-left: 5px;
    width: 160px;
    text-align: center;
  }
}
</style>
