<template>
  <div class="map-control" :style="{ top, bottom, left, right }">
    <div v-if="isActive">
      <v-text-field
        clearable
        size="small"
        @focus="onFocus(true)"
        @blur="onFocus(false)"
        ref="searchautocomplete"
        :prepend-inner-icon="icons.mdiMapSearch"
        style="background-color: white; width: 300px"
        v-model="search"
        filled
        :loading="loading"
        hide-details
        label="Search location"
      >
      </v-text-field>
      <v-icon title="Close" size="18" style="position: absolute; top: 4px; right: 4px" @click="close">{{
        icons.mdiClose
      }}</v-icon>
      <v-list v-show="isFocusing" style="position: absolute; top: 57px; background-color: white; width: 100%" dense>
        <p v-if="locations.length === 0" class="text-center p-0 mb-0">No location found</p>
        <v-list-item-group v-model="selectedLocationIdx" color="primary">
          <v-list-item v-for="(item, i) in locations" :key="i">
            <v-list-item-content>
              <v-list-item-title v-text="item.display_name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>

    <div v-if="!isActive" class="ol-control" style="position: relative">
      <button title="Legend" icon @click="isActive = true">
        <v-icon color="black" size="20">{{ icons.mdiMapSearch }}</v-icon>
      </button>
    </div>
  </div>
</template>
<script>
import { mdiMapSearch, mdiClose } from '@mdi/js'
import axios from 'axios'
import { transformExtent } from 'ol/proj'
import DragResize from '@/components/DragResize.vue'
export default {
  data() {
    return {
      icons: { mdiMapSearch, mdiClose },
      loading: false,
      locations: [],
      search: null,
      selectedLocationIdx: null,
      debounceSearch: null,
      isFocusing: false,
      isActive: false,
    }
  },
  components: { DragResize },
  inject: ['map', 'mapId'],

  methods: {
    close() {
      this.search = null
      this.locations = []
      this.isActive = false
    },

    onFocus(value) {
      setTimeout(() => {
        this.isFocusing = value
      }, 300)
    },

    async fetchData() {
      this.selectedLocationIdx = null
      if (!this.search) {
        this.locations = []
        return
      }
      console.log('zo day')
      this.loading = true
      try {
        const response = await axios.get(`https://nominatim.openstreetmap.org/search.php`, {
          // Replace with your API endpoint
          params: {
            q: this.search,
            format: 'jsonv2',
          },
        })

        this.locations = response.data
      } catch (error) {
        console.log(error)
        console.error('Error fetching data:', error)
        this.locations = []
      } finally {
        this.loading = false
      }
    },

    fetchEntriesDebounced() {
      clearTimeout(this._timerId)
      this._timerId = setTimeout(() => {
        this.fetchData()
      }, 500)
    },
  },
  watch: {
    search() {
      this.fetchEntriesDebounced()
    },
    selectedLocationIdx(idx) {
      if (idx === null) return
      setTimeout(() => {
        this.isFocusing = false
      }, 100)
      const location = this.locations[idx]
      const boundingbox = location.boundingbox.map(parseFloat)
      const extent = transformExtent(
        [boundingbox[2], boundingbox[0], boundingbox[3], boundingbox[1]],
        'EPSG:4326',
        'EPSG:3857',
      )
      this.map.getView().fit(extent, { duration: 0 })
    },
  },
  props: {
    top: {
      type: String,
      default: 'unset',
    },
    bottom: {
      type: String,
      default: 'unset',
    },
    left: {
      type: String,
      default: 'unset',
    },
    right: {
      type: String,
      default: 'unset',
    },
  },
}
</script>
